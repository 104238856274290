/* BlogPostDetail.css */

/* Custom styles for the blog post detail page */
.blog-detail-img {
    width: 50%;
    
  }
  
  .text-uppercase {
    text-transform: uppercase; /* Transform title text to uppercase */
  }
  
  .blog-content {
    margin-top: 20px; /* Add some top margin to the content for separation */
  }
  
  /* Add more styles as needed */
  