/* Top Section */
.home-intro {
  position: relative;
  padding: 60px 0;
  text-align: center;
}

.intro-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #b42b2b;
}

.text-overlay {
  color: #4153d4;
}

.intpro-content {
  margin-bottom: 20px;
}

#scrollDown {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

#scrollDown .icon {
  display: block;
  width: 23px;
  height: 35px;
  position: relative;
  margin: 0 auto 10px;
  background-color: #fff;
  border-radius: 35%;
  border: 2px solid #000; /* Add a black outline */
}

#scrollDown .icon::before,
#scrollDown .icon::after {
  content: '';
  display: block;
  width: 1px;
  height: 8px;
  background-color: #120606; /* Change the color to black */
  position: absolute;
  left: 50%;
  top:12%;
}
.text{
color: #a0a2a5;
text-align: center;
box-sizing: border-box;
margin-top: 8px;
font-size: 13px;
}

/* Scroll Down Icon Animation */
@keyframes scrollMouse {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

/* Add this to your CSS or use inline styles */

.service-card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  min-height: 200px;
  margin: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.service-card:hover {
  transform: scale(1.05);
}

.service-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.service-card p {
  font-size: 1rem;
  margin-bottom: 15px;
}

.service-card button {
  font-size: 1rem;
}

